import kebabCase from 'lodash/kebabCase'
import min from 'lodash/min'
import uniq from 'lodash/uniq'
import { computed, unref, useContext, useRoute, useStore, watch } from '@nuxtjs/composition-api'
import { isBetween } from '~/assets/date'

const saleStatuses = {
  0: 'SALE_STATUS_NONE',
  1: 'SALE_STATUS_FEW_TICKETS',
  2: 'SALE_STATUS_SOLD_OUT',
  3: 'SALE_STATUS_CANCELLED',
  4: 'SALE_STATUS_NOT_SCHEDULED',
}

const saleStatusIcons = {
  1: 'app-circled-exclamation-mark',
  2: 'app-lock',
  3: 'app-circled-exclamation-mark',
  4: 'app-lock',
}

const vriendenLoterijCustomerTagIds = [
  499, // 'VriendenLoterij VIP-KAART'
]

export const educationSchoolTypes = {
  518: 'customer-tag-po-regulier',
  519: 'customer-tag-speciaal',
  520: 'customer-tag-vo-vmbo',
  521: 'customer-tag-vo-have-vwo',
  522: 'customer-tag-mbo',
}

export function setupTix() {
  const store = useStore()
  const { $config, i18n /* , $bridgeFetch */ } = useContext()
  const route = useRoute()
  const currentTixUrl = store.state.tix.tixEnv === 'education' ? $config.edutixSiteUrl : $config.tixSiteUrl

  return {
    // Reactive properties from the store
    tixOrder: computed(() => store.state.tix.order),
    tixOrderSize: computed(() => store.state.tix.order?.items.length || 0),
    tixOrderUrl: computed(() => store.state.tix.order?.url),
    tixProfileUrl: computed(() => store.state.tix.profile),
    tixUser: computed(() => store.state.tix.user),
    isEducationCustomer: computed(() => store.state.tix.isEducationCustomer),

    // Update the store
    /* async */ tixUpdate(data) {
      store.commit('tix/update', data)
      store.commit('favorites/setUser', data?.user)
      // Fetching the customerId belonging to the web user is temporarily disabled - [DESK-2366]
      // if (!store.state.tix.customerId && data?.user?.id && data?.user?.hash) {
      //   store.commit('tix/setCustomerId', await $bridgeFetch(`/api/tix/customer/${data?.user?.id}?hash=${data?.user?.hash}`, { method: 'GET' }))
      // }
    },

    // Static urls are dependent on the tix site url and the current locale
    tixIframeUrl: `${currentTixUrl}/${i18n.locale}/itix`,
    tixLoginUrl: `${currentTixUrl}/${i18n.locale}/login?returnUrl=${encodeURIComponent(`${$config.wwwSiteUrl}${unref(route).fullPath}`)}`,
    tixBaseLoginUrl: `${currentTixUrl}/${i18n.locale}/login`,
    tixOrigin: currentTixUrl,
  }
}

/**
 * Returns a closure that returns reactive helpers related to the price *and* the logged in customer, i.e. minPrice can change when you login
 */
export function setupPrepareTixPricing() {
  const { tixUser } = setupTix()

  return (pricing, eventCustomerTags) => {
    const pricing_ = pricing || []
    const eventCustomerTags_ = eventCustomerTags || []
    const customerTags = computed(() => [null, ...(unref(tixUser)?.tags.map((tag) => tag.id) || [])])
    const availablePrices = computed(() =>
      pricing_.filter(
        (price) =>
          // The current customer must be allowed to see this price
          unref(customerTags).includes(price.customer_tag_id) &&
          // The price must be active
          isBetween('now', price.active_from, price.active_to, true)
      )
    )
    const minPrice = computed(() => {
      // The minPrice is only based on the prices with the 'null' tag
      const defaultPrices = pricing_.filter((price) => !price.customer_tag_id)
      // logic is a bit odd: use the lowest price of the first "ticket_type" group, not the lowest price overall.
      // this is assuming the sorting is correct from the TixImporter from the Bridge
      const firstGroupName = defaultPrices?.[0]?.ticket_type
      const firstGroupPricing = defaultPrices.filter((entry) => entry.ticket_type === firstGroupName).map((entry) => entry.price)
      return min(firstGroupPricing)
    })
    const hasVriendenloterijTicketType = computed(() => eventCustomerTags_.some((customerTag) => vriendenLoterijCustomerTagIds.includes(customerTag.tixId)))
    const matrix = computed(() => ({
      zones: uniq(pricing_.map((price) => price.price_zone)),
      types: uniq(unref(availablePrices).map((price) => price.ticket_type)),
      getPrice(zone, type) {
        for (const price of pricing_) {
          if (price.price_zone === zone && price.ticket_type === type) {
            return price.price
          }
        }
      },
    }))

    return {
      availablePrices,
      customerTags,
      hasVriendenloterijTicketType,
      matrix,
      minPrice,
    }
  }
}

/**
 * Returns reactive helpers related to the price *and* the logged in customer, i.e. minPrice can change when you login
 */
export function setupTixPricing(pricing, eventCustomerTags) {
  return setupPrepareTixPricing()(pricing, eventCustomerTags)
}

/**
 * Returns a closure that returns reactive helpers related to the sale *and* the logged in customer, i.e. saleIsAvailable can change when you login
 */
export function setupPrepareTixSale() {
  const route = useRoute()
  const { tixUser } = setupTix()
  return ({ production, saleDates, saleEndDate, saleStatus }) => {
    const saleDates_ = saleDates || []
    const saleEndDate_ = saleEndDate ? new Date(saleEndDate) : new Date('2222-01-01T12:00:00.000Z')
    const customerTags = computed(() => ['_default', ...(unref(tixUser)?.tags.map((tag) => String(tag.id)) || [])])
    const saleAvailableFromDate = computed(() => {
      // There will never be a sale date when the event has already passed
      if (saleEndDate && saleEndDate_ < new Date()) {
        return undefined
      }

      const availableDates = Object.entries(saleDates_)
        // Filter out all dates that are not part of the customerTags
        .filter(([customerTag, dateString]) => unref(customerTags).includes(customerTag))
        // Map to dateString
        .map(([customerTag, dateString]) => dateString)
        // Sort by dateString to get the earliest date
        .sort()
      // Return the earliest or undefined
      if (availableDates.length) {
        const earliestDate = new Date(availableDates[0])
        // Check that the date is before the saleEndDate
        if (earliestDate < saleEndDate_) {
          return earliestDate
        }
      }
      // No available sale start date
      return undefined
    })

    const saleIsAvailable = computed(() => {
      // The event is for sale when saleStatus is either 0 or 1 AND when the available sale date is in the past
      return [0, 1].includes(saleStatus || 0) && (unref(saleAvailableFromDate) === undefined ? false : unref(saleAvailableFromDate) < new Date())
    })

    const saleIsAvailableInFuture = computed(() => {
      // The event is currently not for sale but will be in the future
      return unref(saleIsAvailable) === false && (unref(saleAvailableFromDate) === undefined ? false : unref(saleAvailableFromDate) > new Date())
    })

    const saleStatus_ = computed(() => {
      if (unref(saleIsAvailable)) {
        // The sale is available.
        // We want to show the saleStatus or fallback to None (which is the same as showing the sale button)
        return saleStatus || 0
      } else {
        // The sale is *not* available.
        // When the status is 'last cards', i.e. `1`, then we do NOT want to show this (it makes no sense to say, last available, without having a sale button)
        // When the status is anything else, then use that status (i.e. the event is not available because it is sold out, code `2`)
        return saleStatus === 1 ? 0 : saleStatus
      }
    })

    const saleIcon = computed(() => {
      return saleStatusIcons[unref(saleStatus_)]
    })

    const saleTemplate = computed(() => {
      return unref(saleStatus_) ? `tix-${kebabCase(saleStatuses[unref(saleStatus_)])}` : undefined
    })

    if (/tix|yes|true|1/.test(unref(route).query.debug)) {
      // We want to show some debug info to make it easier (also on production!) to determine why a sale button is shown
      const log = (color) => {
        const messages = [
          `Event: ${production?.title || 'Unknown event'}`,
          `Available (now, future): ${unref(saleIsAvailable) ? 'yes' : 'no'}, ${unref(saleIsAvailableInFuture) ? 'yes' : 'no'}`,
          `Status: ${saleStatuses[unref(saleStatus_)]}`,
          `Customer tags: ${unref(customerTags)}`,
          `Available between: ${unref(saleAvailableFromDate)?.toISOString()} and ${saleEndDate_?.toISOString()}`,
        ]
        // eslint-disable-next-line no-console
        console.debug(`%c${messages.join('\n')}`, `border-left: 2px solid ${color}; color: ${color}; padding-left: 5px`)
      }
      log('#003366')
      watch([customerTags, saleAvailableFromDate, saleIsAvailable, saleIsAvailableInFuture, saleStatus_], () => log('#660033'))
    }

    return {
      saleAvailableFromDate,
      saleIcon,
      saleIsAvailable,
      saleIsAvailableInFuture,
      saleStatus: saleStatus_,
      saleTemplate,
    }
  }
}

/**
 * Returns reactive helpers related to the sale *and* the logged in customer, i.e. saleIsAvailable can change when you login
 */
export function setupTixSale(tixSale) {
  return setupPrepareTixSale()(tixSale)
}

/**
 * This is a simplified version of setupPrepareTixSale.
 * The events are bookable via the "saleStatus" and from the "saleDates".
 * Logic based from old Symfony stack in "\Zicht\Bundle\ConcEducationBundle\Entity\Event::isBookable".
 */
export function setupPrepareEducationTixSale() {
  const route = useRoute()
  return ({ saleDates, saleStatus, saleEndDate }) => {
    const saleDates_ = saleDates || []
    const saleEndDate_ = saleEndDate ? new Date(saleEndDate) : new Date('2222-01-01T12:00:00.000Z')
    const educationTags = Object.keys(educationSchoolTypes).map((tagId) => String(tagId)) || []
    const bookableFromDate = computed(() => {
      // There will never be a sale date when the event has already passed
      if (saleEndDate && saleEndDate_ < new Date()) {
        return undefined
      }

      const availableDates = Object.entries(saleDates_)
        // Filter out all dates that are not part of the customerTags
        .filter(([customerTag, dateString]) => educationTags.includes(customerTag))
        // Map to dateString
        .map(([customerTag, dateString]) => dateString)
        // Sort by dateString to get the earliest date
        .sort()

      // Return the earliest or undefined
      if (availableDates.length) {
        const earliestDate = new Date(availableDates[0])
        // Check that the date is before the saleEndDate
        if (earliestDate < saleEndDate_) {
          return earliestDate
        }
      }

      // No available sale start date
      return undefined
    })

    const isBookable = computed(() => {
      // The event is for sale when saleStatus is either 0 or 1 AND when the available sale date is in the past
      return [0, 1].includes(saleStatus || 0) && (unref(bookableFromDate) === undefined ? false : unref(bookableFromDate) < new Date())
    })

    if (/tix|yes|true|1/.test(unref(route).query.debug)) {
      // We want to show some debug info to make it easier to determine if an event is bookable
      const log = (color) => {
        const messages = [
          `Bookable: ${unref(isBookable) ? 'yes' : 'no'}`,
          `Status: ${saleStatuses[saleStatus]}`,
          `Available between: ${unref(bookableFromDate)?.toISOString()} and ${saleEndDate_?.toISOString()}`,
        ]
        // eslint-disable-next-line no-console
        console.debug(`%c${messages.join('\n')}`, `border-left: 2px solid ${color}; color: ${color}; padding-left: 5px`)
      }
      log('#003366')
      watch([isBookable, bookableFromDate], () => log('#660033'))
    }

    return {
      isBookable,
    }
  }
}
