
import { defineComponent, onMounted, onUnmounted, ref, useStore, watch } from '@nuxtjs/composition-api'
import { setupTix } from '~/assets/composition/tix'

export default defineComponent({
  setup() {
    const { tixIframeUrl, tixUpdate } = setupTix()
    const store = useStore()
    const tixIframe = ref(null)

    watch(tixIframe, (node) => {
      store.commit('tix/setIframe', node)
    })

    const responseListener = (messageEvent) => {
      store.dispatch('tix/response', { messageEvent })
    }

    store.commit('tix/setCallback', async () => {
      await store
        .dispatch('tix/call', { call: 'GetSession', reload: false })
        .then(async (data) => {
          await tixUpdate(data)
        })
        .catch((e) => {
          console.error('Error calling GetSession', e)
        })
    })

    const onLoad = (event) => {
      store.state.tix.callback(event)
    }

    // Make sure that the event listener is also removed, otherwise they will accumulate
    onMounted(() => window.addEventListener('message', responseListener))
    onUnmounted(() => window.removeEventListener('message', responseListener))

    return { tixIframe, tixIframeUrl, onLoad }
  },
})
